import React, {useState, useEffect} from "react";
import { useInView } from 'react-intersection-observer';

import initialBackground from "../../assets/img/initial-background.png";
import logo from "../../assets/img/logo.png";
import mockupNotebook from "../../assets/img/mockup-notebook.png";
import useWindowSize from "../states/useWindowSize";
import "./InitialSection.scss";

const FadeInSection = ({ children }) => {
    const { ref, inView, entry } = useInView({
      threshold: 0.1,
    });
  
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      if (inView) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, [inView]);
  
    return (
      <div ref={ref} className={`fade-in-section ${isVisible ? 'visible' : ''}`}>
        {children}
      </div>
    );
};

export default function InitialSection() {
  const { width, height } = useWindowSize();

  function handleRedirectOrcamento() {
    const email = 'contact@bizvisiontech.com';
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  }

  return (
    <section className="initial-section" style={{width: width * 0.92, height: window.innerHeight}}>
      <img src={initialBackground} style={{height: height}} alt="background" className="initial-section__background" />
      <img src={logo} alt="logo" className="initial-section__logo" />
      
      <div className="initial-section__content" style={{width: width * 0.5 - 50}}>
        <FadeInSection>
          <h1 className="initial-section__title">
            Boost Your Business Performance through Technology and Data Analysis
          </h1>
        </FadeInSection>
        <FadeInSection>
          <p className="initial-section__description">
          We transform complex data into actionable insights that drive growth and efficiency in your business. With our 360° methodology, we offer integrated technological solutions that put the control of your company in the palm of your hand.
          </p>
        </FadeInSection>
        <FadeInSection>
          <button className="initial-section__button" onClick={handleRedirectOrcamento}>Contact Us</button>
        </FadeInSection>
      </div>
      <img src={mockupNotebook} alt="mockup-notebook" className="initial-section__mockup-notebook" />
    </section>
  );
}
