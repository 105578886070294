import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';

import system1 from "../../assets/img/system1.png";
import system2 from "../../assets/img/system2.png";
import system3 from "../../assets/img/system3.png";
import system4 from "../../assets/img/system4.png";
import system5 from "../../assets/img/system5.png";
import system6 from "../../assets/img/system6.png";
import system7 from "../../assets/img/system7.png";
import system8 from "../../assets/img/system8.png";
import system9 from "../../assets/img/system9.png";
import system10 from "../../assets/img/system10.png";
import "./systemsSection.scss";

import useWindowSize from "../states/useWindowSize";

const FadeInSection = ({ children }) => {
    const { ref, inView, entry } = useInView({
      threshold: 0.1,
    });
  
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      if (inView) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, [inView]);
  
    return (
      <div ref={ref} className={`fade-in-section ${isVisible ? 'visible' : ''}`}>
        {children}
      </div>
    );
};

export default function SystemsSection() {
  const { width, height } = useWindowSize();

  return (
    <section className="systems-section" style={{ width: width }}>
        <FadeInSection>
        <h2>See any systems that we connect:</h2>
        </FadeInSection>
        <FadeInSection>
          <div className="systems-section__content">
              <div className="systems-grid">
              <img src={system1} alt="System 1" className="system-image" />
              <img src={system2} alt="System 2" className="system-image" />
              <img src={system3} alt="System 3" className="system-image" />
              <img src={system4} alt="System 4" className="system-image" />
              <img src={system5} alt="System 5" className="system-image" />
              <img src={system6} alt="System 6" className="system-image" />
              <img src={system7} alt="System 7" className="system-image" />
              <img src={system8} alt="System 8" className="system-image" />
              <img src={system9} alt="System 9" className="system-image" />
              <img src={system10} alt="System 10" className="system-image" />
              </div>
          </div>
        </FadeInSection>
    </section>
  );
}