import './App.scss';
import InitialSection from './components/initial-section/InitialSection';
import LearnAboutSection from './components/learn-about-section/LearnAboutSection';
import DiferenciaisSection from './components/diferenciais-section/DiferenciaisSection';
import Footer from './components/footer/Footer';
import SystemsSection from './components/systems-section/SystemsSection';

function App() {
  return (
    <div className="App">
      <InitialSection />
      <LearnAboutSection />
      <DiferenciaisSection />
      <SystemsSection />
      <Footer />
    </div>
  );
}

export default App;
