import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';

import "./diferenciaisSection.scss";
import relatorios from "../../assets/img/relatorios.png";
import grupos from "../../assets/img/grupos.png";
import filtros from "../../assets/img/filtros.png";

import useWindowSize from "../states/useWindowSize";

const FadeInSection = ({ children }) => {
    const { ref, inView, entry } = useInView({
      threshold: 0.15,
    });
  
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      if (inView) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, [inView]);
  
    return (
      <div ref={ref} className={`fade-in-section ${isVisible ? 'visible' : ''}`}>
        {children}
      </div>
    );
};

export default function DiferenciaisSection() {
    const { width, height } = useWindowSize();

    function handleRedirectOrcamento() {
      const email = 'contact@bizvisiontech.com';
      const mailtoLink = `mailto:${email}`;
      window.location.href = mailtoLink;
    }

    return (
        <section className="diferenciais-section">
            <div className="background-diferenciais-section" style={{width: width}}>
                <FadeInSection>
                  <h2>Discover Our Other Services</h2>
                </FadeInSection>
                <FadeInSection>
                  <div className="diferenciais-section__content">
                      <div className="diferenciais-section__content__item">
                        <img src={relatorios} alt="Diferencial 1" />
                        <div className="diferenciais-section__content__item__texts">
                          <div className="listra"></div>
                          <h3>Business Consulting</h3>
                          <p>We offer specialized consulting that covers the development of DataWarehouses, ETL processes (Extraction, Transformation, and Loading of Data), development of dashboards (Power BI, Looker Studio, and Tableau), and Data Lakes. Our approach is personalized to meet the specific needs of each client, ensuring effective and long-lasting results.
                          </p>
                          <button onClick={handleRedirectOrcamento} className="btn-diferencial-item">Contact Us</button>
                        </div>
                      </div>
                  </div>
                </FadeInSection>
                <FadeInSection>
                  <div className="diferenciais-section__content">
                      <div className="diferenciais-section__content__item">
                        <div className="diferenciais-section__content__item__texts">
                          <div className="listra"></div>
                          <h3>Online and In-Company Training</h3>
                          <p>
                            Our training programs cover everything from Power BI and Looker Studio to the fundamentals of mathematics and statistics for data analysis. We also offer customized courses based on demand, providing teams with the necessary skills to extract maximum value from data.
                          </p>
                          <button onClick={handleRedirectOrcamento} className="btn-diferencial-item">Contact Us</button>
                        </div>
                        <img src={filtros} alt="Diferencial 2" />
                      </div>
                  </div>
                </FadeInSection>
                <FadeInSection>
                  <div className="diferenciais-section__content last-diferenciais">
                      <div className="diferenciais-section__content__item">
                          <img src={grupos} alt="Diferencial 1" />
                          <div className="diferenciais-section__content__item__texts">
                              <div className="listra"></div>
                              <h3>Professional Outsourcing</h3>
                              <p>We provide BI analysts, data engineers, and developers to integrate into your team. Our professionals are highly qualified and prepared to drive your data projects, ensuring efficiency and results.</p>
                              <button onClick={handleRedirectOrcamento} className="btn-diferencial-item">Contact Us</button>
                          </div>
                      </div>
                  </div>
              </FadeInSection>
            </div>
        </section>
    );
}
