import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';

import item1 from "../../assets/img/item-1.png";
import item2 from "../../assets/img/item-2.png";
import item3 from "../../assets/img/item-3.png";
import notebookRelatorios from "../../assets/img/notebook-relatorios.png";
import notebookRelatorio from "../../assets/img/notebook-relatorio.png";
import setas from "../../assets/img/setas.png";
import tabletMockup from "../../assets/img/mockup-tablet.png";
import "./learnAboutSection.scss";

import useWindowSize from "../states/useWindowSize";

const FadeInSection = ({ children }) => {
    const { ref, inView, entry } = useInView({
      threshold: 0.1,
    });
  
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      if (inView) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, [inView]);
  
    return (
      <div ref={ref} className={`fade-in-section ${isVisible ? 'visible' : ''}`}>
        {children}
      </div>
    );
};

export default function LearnAboutSection() {
  const { width, height } = useWindowSize();

  function handleRedirectOrcamento() {
    const email = 'contact@bizvisiontech.com';
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  }

  return (
    <section className="learn-about-section" style={{ width: width }}>
        <FadeInSection>
          <h2>Get Ready to Revolutionize Your Business Management with Complete and Innovative Solutions</h2>
          <p>Discover our 360° Solution that we will implement in your company:</p>
        </FadeInSection>
      <div className="learn-about-section__content">
        <div className="left-content">
          <FadeInSection>
            <div className="left-content-proposta">
              <h3>Exclusive Management Platform</h3>
              <p>We deliver these functionalities in an exclusive data management platform. Through it, you will be able to have a macro view of the data, group and add users, and share data management with other managers.<br/>
                All of this in a unique and secure environment.</p>
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className="images-content">
              <img src={notebookRelatorios} className="notebookRelatorios" alt="notebookRelatorios" />
              <img src={setas} className="setas" alt="setas" />
              <img src={notebookRelatorio} className="notebookRelatorios" alt="notebookRelatorio" />
            </div>
          </FadeInSection>
        </div>
        <div className="right-content">
          <div className="right-content-items">
            <FadeInSection>
              <div className="items item-first">
                <div className="item__image__title">
                  <img src={item1} alt="item1" />
                  <h3>Customized Dashboards</h3>
                </div>
                <p>We transform complex data into actionable insights that drive growth and efficiency in your business. With our 360° methodology, we offer integrated technological solutions that put the control of your company in the palm of your hand.</p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="items">
                <div className="item__image__title">
                  <img src={item2} alt="item1" />
                  <h3>Smartphone App</h3>
                </div>
                <p>With our smartphone app, you can access your data from anywhere in the world. Make quick and informed decisions, no matter where you are.</p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="items item-last">
                <div className="item__image__title">
                  <img src={item3} alt="item1" />
                  <h3>Automated Email</h3>
                </div>
                <p>Receive key data about your company in your email periodically. Information such as sales numbers, goal projections, sales rankings, and much more, summarized and clear.</p>
              </div>
            </FadeInSection>
          </div>
          <FadeInSection>
            <button onClick={handleRedirectOrcamento}>Contact Us</button>
          </FadeInSection>
        </div>
      </div>
      <div className="video-plataforma">
          <div className="video-plataforma__content">
            <h3>Discover our Platform:</h3>
            <iframe height={height * 0.47} src="https://www.youtube.com/embed/2e9mVGpqoIc?si=qORJzGeFRlunU7y8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <img className="mockupTablet" src={tabletMockup} alt="tabletMockup" />
      </div>
    </section>
  );
}