import React from "react";
import { FaInstagram, FaLinkedin } from 'react-icons/fa';

import useWindowSize from "../states/useWindowSize";
import "./footer.scss";

import email from "../../assets/img/email.png";
import location from "../../assets/img/location.png";
import soaresLogoFooter from "../../assets/img/soares-logo-footer.png";
import telefone from "../../assets/img/telefone.png";


export default function Footer() {
    const { width, height } = useWindowSize();

    function handleRedirectSocialMedia(link) {
        window.open(link);
    }

    return (
        <footer className="footer">
            <div className="footer__content" style={{ width: width }}>
                <div className="footer__content_wd">
                    <div className="footer__left">
                        <div className="item">
                            <img src={location} alt="Localização" />
                            <p>1309 Coffeen Avenue STE 1200, Sheridan, Wyoming, 82801</p>
                        </div>
                        <div className="item">
                            <img src={email} alt="Localização" />
                            <p>contact@bizvisiontech.com</p>
                        </div>
                    </div>
                    <div className="footer__right">
                        <img src={soaresLogoFooter} className="logoFooter" alt="Logo Soares" />
                        <div className="footer__right__social">
                            <FaInstagram className="icon-socialmedia" onClick={() => handleRedirectSocialMedia('https://www.instagram.com/bizvisiontech/')} style={{ color: 'white', fontSize: '24px' }} />
                            <FaLinkedin className="icon-socialmedia" onClick={() => handleRedirectSocialMedia('https://www.linkedin.com/company/bizvision-tech/')} style={{ color: 'white', fontSize: '24px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
